@mixin label-text {
  font-family: Roboto;
  font-size: 14px;
  color: #686868;
  margin-bottom: 10px;
}

.show-block {
  display: flex;
  margin-top: 40px;
}

.show-block-form {
  max-width: 410px;
}

.show-block-additions {
  margin-left: 80px;
  width: 60%;
}

.banner-show-block-additions {
  margin-left: 80px;
  width: 510px;
}

.download-image-row {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.download-image-row-row-error {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border: 1px solid red;
  border-radius: 5px;
  padding: 10px;
}

.comment-block {
  margin-top: 40px;
  text-align: justify;

  span {
  @include label-text;
  }
}

.preview-container {
  text-align: justify;
  margin-top: 28px;
  span {
    @include label-text;
  }

  .preview-block {
    width: 409px;
    height: 234px;
    background-color: #d8d8d8;
  }
}

.editions-preview-container{
  text-align: justify;
  margin-top: 28px;
  span {
    @include label-text;
  }

.editions-preview-block {
    width: 255px;
    height: 325px;
    background-color: #d8d8d8;
    img {
      height: 100%;
      width: 100%;
    }
  }
}

.tags-buttons-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 15px;
}

.tag-item {
  border-radius: 4px;
  box-shadow: 0 5px 16px 0 rgba(226, 230, 245, 0.8);
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 10px 13px 10px 13px;

  span {
    font-family: AktivGrotesk;
    font-size: 14px;
    color: #686868;
  }
  .tag-remove {
    margin-left: 3px;
  }
}

.add-tag-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.bottom-submit-form {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 25px;
  span {
    font-family: Roboto;
    font-size: 18px;
    line-height: 1.33;
  }
}

.description-block {
  text-align: left;
  margin-top: 20px;
  span {
    font-family: Roboto;
    font-size: 18px;
    line-height: 1.33;
    color: #686868;
  }
}

.addresses-block {
  border: 1px solid #bcbcbc;
  border-radius: 5px;
  padding: 20px;
  margin-top: 20px;
}

.btn-upload-disable {
  cursor: not-allowed;
  pointer-events: none;
}

.btn-upload-able {
  background-color: #02213d;
  color: #ffffff;
}

.nodes-show-block-form {
  width: 50%;
}

.internal-header {
  text-align: left;
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 10px;
}

.internal-header-error {
  @extend .internal-header;
  border: 1px solid red;
}

.node-create-submit {
  margin-top: 40px;
  text-align: left;
}

.node-color-block {
  border: 1px solid;
  border-radius: 6px;
  padding: 10px;
  margin-top: 40px;
  h4 {
    color: #959595;
  }
}

.partners-show-block-form {
  width: 30%;
}

.contact-localization {
   display: flex;
  align-items: center;
}

.employee-contact-header {
  text-align: left;
}

.employee-contact-block {
  margin-top: 20px;
}