.UploadFileComponent {
    height: 150px;
    position: relative;
    border-radius: 5px;
    margin-top: 5px;
}

.UploadFileComponent__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    &.remove {
        z-index: -10;
    }
}

.UploadFileComponent__label {
    background: #eeeeee;
    border: 1px dashed #888;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 25px;
    text-align: center;
    line-height: 1.5;

    p {
        line-height: 1.5;
    }

    .copy-link {
        font-size: 16px;
        width: 100%;
        margin-top: 10px;
        padding: 0 4px;
        border-radius: 3px;
        border: 1px solid #e1e1e1;
        line-height: 26px;
    }

    div {
        width: 100%;
    }
}