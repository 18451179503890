.category-create_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
}

.menu-create_wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
  margin: auto;
  width: 50%;
  .form-wrapper {
    width: 100%;
  }
}

.submit-button_wrapper {
  text-align: right;
  width: 100%;
}

.form-wrapper {
  padding: 15px 10px 15px 0;
  width: 25%;

  form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }
}

.form-wrapper.form-big {
  padding: 15px 0 15px 10px;
  width: 75%;
}

.editor {
  width: 100%;
}

div.DraftEditor-root {
  background-color: #fffef7;
  border: 1px solid lightsteelblue;
  width:80%;
  margin:auto;
  height:300px;
  overflow-y: scroll;
  padding:0;
  font-size: 18px;
  font-family: 'calibri', sans-serif;
}

div.DraftEditor-editorContainer {
  padding: 20px;
}

div.toolbar {
  position: relative;
  width: 80%;
  background-color: lightsteelblue;
  padding: 10px 20px;
  margin: auto;
  text-align: right;
}

div.toolbar button {
  border: 1px solid black;
  padding:5px;
  margin-right:4px;
  background-color:black;
  color:white;
  &:hover {
    cursor: pointer;
  }
}

.react-datepicker-wrapper {
  width: 100%;
  .react-datepicker__input-container {
    width: 100%;
  }
  input {
    margin-top: 5px;
    border-radius: 5px;
    text-align: center;
    padding: 10px;
    font-size: 14px;
    width: 100%;
  }
}