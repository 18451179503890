.account-submit-block {
  display: flex;
  height: 30px;
  justify-content: space-between;
  margin-top: 40px;
}

.submit-button_block {
  text-align: right;
  width: 93%;
}
.table-cell-wrap {
  display: flex;
}
