.user-content {

}

.user-tabs-wrapper {
  border-bottom: .5px solid #c7c7c7;
}

.tab-line {
  display: flex;
  justify-content: left;
}

.tab-item {
  margin-right: 50px;
  margin-left: 50px;
}

.tab-item-block {
  font-size: 15px;
  color: #686868;
  padding: 0 0 15px 0;
  &:hover {
    cursor: pointer;
  }

  &.tab-none {
    display: none;
  }
}

.tab-active > div {
  font-size: 15px;
  color: #02213d;
  border-bottom: 2px solid #02213d;
}