
.header-wrapper {
  display: flex;
  justify-content: space-between;
  border-bottom: .5px solid #e8e8e8;
}

.header-main-block {
  display: flex;
  justify-content: space-between;
  height: 70px;
  margin-right: 35px;
}

.notification-icon-block {
  margin-left: 50px;
  margin-right: 50px;
}

.global-search-row {
  display: flex;
  align-items: center;
  span {
    margin-left: 15px;
  }
}

.ck-editor {
  ul {
    padding-left: 18px;
  }

  ol {
    padding-left: 18px;
  }
}
