.tab-block-content {
  margin: 20px 20px 0 20px;
}

.warningNotification {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  font-size: 15px;
  color: white;
  position:fixed;
  width:300px;
  height: fit-content;
  background:lightcoral;
  border-radius: 6px;
  right:20px;
  bottom:100px;
}

.warningNotificationMessage {
  width: 80%;
  height: fit-content;
}

.successNotification {
  padding: 10px;
  font-size: 18px;
  color: white;
  position: fixed;
  width: 300px;
  height: 100px;
  background: lightgreen;
  border-radius: 6px;
  right: 20px;
  bottom: 100px;
}

.banner-table-image {
  height: 80px;
}

.editions-table-image {
  width: 60px;
  height: 80px;
}

.logo-container {
  width: auto;
  height: 50px;
  margin-left: 30px;
  margin-top: 10px;
  img {
    max-width: 100%;
    height: 100%;
    width: 100%;
  }
}

.no-shadow {
  div {
    box-shadow: unset;
  }
}


.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App {
  display: flex;
  flex-wrap: wrap;
}

.App-main {
  padding: 20px;
  width: 85%;
}

.App-sidebar {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
  text-align: left;
  overflow: auto;
  width: 15%;
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
  border-bottom: .5px solid #e8e8e8;
  width: 100%;
}

.ck-editor__main {
  text-align: left;
  .ck-editor__editable {
    min-height: 200px;
  }
}

.login-wrapper {
  margin: 50px auto;
  text-align: center;
  width: 50%;
}

th, td {
  padding: 0 5px !important;
}

.filepicker.dropzone.dz-clickable {
  min-height: auto;
  width: 100%;
  .dz-default.dz-message {
    margin: 0 auto;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
