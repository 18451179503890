.content-wrapper {
  display: flex;
}

.content-block {
  width: 100%;
}

.side-nav-list {
  width: fit-content;
  height: 100%;
  text-align: left;
}

.side-nav-item {
  font-size: 15px;
  color: #686868;
  height: fit-content;
  margin: 5px 0;
  padding-top: 4px;
  padding-bottom: 4px;
  span {
    margin: 0 25px 0 25px;
  }
}

.active {
  @extend .side-nav-item;
  color: #02213d;
  border-right: 4px solid #02213d;
}

.breadcrumb-block {
  text-align: left;
  padding: 30px 0 30px 50px;
}

.checkbox-is-sale-block {
  text-align: left;
  span {
    font-family: Roboto;
    font-size: 18px;
    line-height: 1.33;
  }
}

.tag-colors-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 30px;
}

.tag-colors-item {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  font-size: 10px;
  border: 1px solid #3b3b3b;
}

.tag-color-block {
  border-bottom: 1px solid #3b3b3b;
  padding-bottom: 10px;
  p {
    font-family: Roboto;
    font-size: 17px;
    line-height: 1.33;
    color: #686868;
  }
}

.tag-color-block-error {
  @extend .tag-color-block;
  border-bottom: 1px solid #b20516;
}