@mixin label-text {
  font-family: Roboto;
  font-size: 14px;
  color: #686868;
  margin-bottom: 10px;
}

.departments-table-image {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.departments-preview-container {
  text-align: justify;
  margin-top: 28px;

  span {
    @include label-text;
  }

  .departments-preview-block {
    width: 190px;
    height: 190px;
    background-color: #d8d8d8;

    img {
      height: 100%;
      width: 100%;
    }
  }
}

.department-download-image-row {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 20px;
}

.empty-error {
  border: 1px solid red;
}

.is-active-block {
  text-align: left;
  margin-top: 20px;
}