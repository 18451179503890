.large-thumbnail {
  display: block;
  max-height: 400px;
  border: 10px solid #fff;
  box-shadow: 0 0 5px #aaa;
  position: absolute;
  top: 0;
  left: -9999px;
  z-index: 1;
  opacity: 0;
  transition: opacity .5s ease-in-out;
  -moz-transition: opacity .5s ease-in-out;
  -webkit-transition: opacity .5s ease-in-out;
}

.thumbnail {
  object-fit: cover;
  width: 80px;
  height: 80px;
}

.thumbnail-holder {
  &:hover .large-thumbnail {
    top: 25%;
    left: 25%;
    z-index: 1;
    opacity: 1;
  }
}