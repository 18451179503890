.datepicker-wrapper {
  margin: 20px 0;
  flex: 1.8;
}

.datepicker-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border: 1px solid #444342;
  padding: 20px;
  border-radius: 5px;
}

.filters-list {
  display: flex;
  flex-wrap: wrap;
  max-height: 160px;
  overflow-y: auto;
}

.filters-item {
  display: flex;
  flex-wrap: wrap;
  width: 33%;
  padding: 10px 10px 10px 0;

  label {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    &:hover {
      cursor: pointer;
    }
    p {
      flex: 1;
    }
  }

  input:checked + .checkbox-facade {
    background: #df6d17;
  }
}

.checkbox-wrapper {
  display: flex;
  margin-right: 10px;
}

.checkbox-wrapper input {
  height: 0;
  width: 0;
}

.checkbox-facade {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  background-color: #e9e9e9;
}

.divider {
  font-size: 24px;
  color: #acacac;
}

.dp-inputs-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;

  input {
    border-radius: 6px;
    background: #f6f6f6;
    padding: 3px 10px;
    font-size: 14px;
    height: 35px;
    color: #444342;
    width: 35%;
  }

  input.activeD {
    background: #cbcbcb;
    color: #fff;
  }
}

.overflow {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.date-picker-header {
  margin: 40px 0;
  text-align: left;
  font-family: Roboto;
  font-size: 18px;
  line-height: 1.33;
  color: #686868;
}